<template>
  <v-app>
    <dashboard-core-app-bar v-model="expandOnHover" />

    <dashboard-core-drawer :expand-on-hover.sync="expandOnHover" />

    <dashboard-core-view />
    <dashboard-core-settings v-model="expandOnHover" />
    <Snackbar />
    <Loader />
  </v-app>
</template>

<script>
export default {
  name: "DashboardIndex",
  components: {
    DashboardCoreAppBar: () => import("./components/AppBar"),
    DashboardCoreDrawer: () => import("./components/Drawer"),
    DashboardCoreSettings: () => import("./components/Settings"),
    DashboardCoreView: () => import("./components/View"),
    Snackbar: () => import("../../components/Snackbar"),
    Loader: () => import("../../components/Loader"),
  },

  data: () => ({
    color: "error",
    snackbar: true,
  }),
  computed: {
    expandOnHover: {
      set(val) {
        this.$store.commit("app/SET_EXPAND_ON_HOVER", val);
      },
      get() {
        return this.$store.state.app.expandOnHover;
      },
    },
  },
};
</script>
